export const routes = {
    loginRoute: '/login',
    userRoute: '/admin/users',
    transactionRoute: '/transactions/',
    getDashboardStatRoute: '/admin/dashboard-stats',
    activateUserRoute: '/admin/users/activate',
    exportUserDataRoute: '/admin/users/export/excel',
    sendEmailVerificationRoute: '/admin/users/resend-email-verification-code',
    suspendUserRoute: '/admin/users/suspend',
    emailConfirmationRoute: '/email-confirmation?token=',
    requeryRoute: '/admin/re-query-payment',
    requeryRouteNew: '/transactions/re-query-payment',
    transactionWithFilterRoute: '/transactions/with-filters',
    notificationRoute: '/notifications',
    servicesRoute: '/services',
    roleTypeRoute: '/data/role-types',
    discountTypeRoute: '/data/coupon-discount-types',
    couponRoute: '/coupon',
    walletBalanceRoute: '/data/provider-wallet-balance',
    providusRepush: '/providus/admin/providus_repush',
}
