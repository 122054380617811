import React, { useState } from "react";
import { Tabs, Table as AntTable, Tag, Popover, Modal, Button, Input, Select, Collapse, Upload } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { TableComponent } from "../../globalComponents/TableComponent";
import { PageTitleBar } from "../../globalComponents/PageTitleBar";
import { PrimaryButton } from '../../globalComponents/Buttons';
import { FiMoreVertical } from "react-icons/fi";
import { selectUser, suspendUser } from "./slice";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {fetchAllUser } from "./slice";
import { useDispatch } from "react-redux";
import { notificationAlert } from "../../../utils/notificationAlert";
import { MainPageScaffold } from "../../globalComponents/MainPageScaffold";
import { Services } from "../../../services";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Dragger } = Upload;

export const HelpCenter = (props) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const userState = useSelector(selectUser);
  const [activeTab, setActiveTab] = useState("1");
  const [activeUserPageSize, setActiveUserPageSize] = useState(10);
  const [searchValues, setSearchValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const dispatcher = useDispatch();

  const columns = [
    {
      title: "Question",
      // dataIndex: "fullName",
      // key: "fullName",
    },
    {
      title: "Answers",
      // dataIndex: "email",
      // key: "email",
    },
    {
      title: "Category",
      // dataIndex: "email",
      // key: "email",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, alldata) => {
        const content = (
          <div>
            <p
              style={{ cursor: "pointer" }}
            >
              Edit
            </p>
            <p
              style={{ cursor: "pointer" }}
            >
              Deactivate
            </p>
            <p
              style={{ cursor: "pointer" }}
            >
              Delete
            </p>
          </div>
        );

        return (
          <Popover trigger="click" content={content}>
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const gotoAllUserTable = () => {
    history.push(`${url}/table`);
  };
  const handleRow = (type) => (record, rowIndex) => {
    return {
      onDoubleClick: (event) => {
        localStorage.userStatus = type;
        history.push(`${url}/${record.email}`);
      },
    };
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handleActiveUserPagination = (page, pageSize) => {
    setActiveUserPageSize(pageSize);
    dispatcher(fetchAllUser({ page: page - 1, pageSize, status: "ACTIVE" }));
  };

  const handleUserSearch = (event) => {
    (async () => {
      try {
        console.log(event);
        setSearching(Boolean(event.target.value))
        const response = await Services.searchUser({
          search: event.target.value,
        });
        setSearchValues(response.data.data.body);
        console.log(response.data.data.body);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  const [addFAQModal, setAddFAQModal] = useState(false)
  const [policyUploadModal, setPolicyUploadModal] = useState(false)
  const [termsUploadModal, setTermsUploadModal] = useState(false)

  const uploadHand = () =>{
    console.log('Hello')
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    customRequest: dummyRequest,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: file => {
      const reader = new FileReader();

      reader.onload = e => {
          console.log(e.target);
      };
      reader.readAsText(file);

      // Prevent upload
      return false;
  },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <MainPageScaffold onSearch={handleUserSearch} showSearch>
            <PageTitleBar hideButtons={true} title="Help Center" />
            <TableComponent onClick={gotoAllUserTable}>
              {isSearching ? (
                <StyledAntTable
                  onRow={handleRow("active")}
                  columns={columns}
                  dataSource={searchValues}
                  pagination={false}
                />
              ) : (
                <Tabs
                  tabBarStyle={{ color: "#A0AEC0", padding: "0px 23px" }}
                  defaultActiveKey="1"
                  onChange={onTabChange}
                >
                  <TabPane tab="FAQs" key="1">
                  <div className="repush-btn-div">
                    <div>
                        <PrimaryButton onClick={() => setAddFAQModal(true)} text='Add New FAQ'/>
                    </div>
                    <Modal title="New FAQ" open={addFAQModal} footer={null} onCancel={() => setAddFAQModal(false)}>
                        
                          <Input name='question'  placeholder="Question" />
                            <br />
                            <br />
                          <Input name='answer'  placeholder="FAQ Answer" />
                            <br />
                            <br />
                            <Select
                              className="formControl"
                              showSearch
                              placeholder="Select Category"
                              options={[
                                {
                                  value: 'Category',
                                  label: 'Category 1',
                                },
                                {
                                  value: 'Category',
                                  label: 'Category 2',
                                },
                                {
                                  value: 'Category',
                                  label: 'Category 3',
                                },
                              ]}
                            />
                            <br/>
                            <br/>
                          <Button>Add FAQ</Button>
      
                    </Modal>
                </div>
                    <StyledAntTable
                      onRow={handleRow("active")}
                      columns={columns}
                      dataSource={userState.activeUserList.data}
                      pagination={{
                        total: userState.activeUserList.total,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                        defaultCurrent: 1,
                        current: userState.activeUserList.page + 1,
                        pageSize: activeUserPageSize,
                        showSizeChanger: true,
                        onChange: handleActiveUserPagination,
                      }}
                    />
                  </TabPane>

                  <TabPane tab="Policies" key="2">
                    <div className="padding-2">
                      <Collapse bordered={false} size='large' defaultActiveKey={['1']}>
                        <Panel header="Privacy Policy" key="1">
                        <div className="repush-btn-div">
                          <Button onClick={() => setPolicyUploadModal(true)} >Add Privacy Policy</Button>
                        </div>
                        </Panel>
                        <Panel header="Purchase Policy" key="2">
                          <div className="repush-btn-div">
                            <Button onClick={() => setPolicyUploadModal(true)} >Add Purchase Policy</Button>
                          </div>
                        </Panel>
                        <Panel header="Delivery Policy" key="3">
                          <div className="repush-btn-div">
                            <Button onClick={() => setPolicyUploadModal(true)} >Add Delivery Policy</Button>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </TabPane>

                  <TabPane tab="Terms & Conditions" key="3">
                    <div className="padding-2">
                      <Collapse bordered={false} size='large' defaultActiveKey={['1']}>
                        <Panel header="Creator T/C" key="1">
                        <div className="repush-btn-div">
                          <Button onClick={() => setPolicyUploadModal(true)} >Add Creator T/C</Button>
                        </div>
                        </Panel>
                        <Panel header="Partner T/C" key="2">
                          <div className="repush-btn-div">
                            <Button onClick={() => setPolicyUploadModal(true)} >Add Partner T/C</Button>
                          </div>
                        </Panel>
                        <Panel header="Vendor T/C" key="3">
                          <div className="repush-btn-div">
                            <Button onClick={() => setPolicyUploadModal(true)} >Add Vendor T/C</Button>
                          </div>
                        </Panel>
                        <Panel header="Service Provider T/C" key="4">
                          <div className="repush-btn-div">
                            <Button onClick={() => setPolicyUploadModal(true)} >Add Service Provider T/C</Button>
                          </div>
                        </Panel>
                        <Panel header="Leader T/C" key="5">
                          <div className="repush-btn-div">
                            <Button onClick={() => setPolicyUploadModal(true)} >Add Leader T/C</Button>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </TabPane>
                </Tabs>
              )}
            </TableComponent>

            <Modal title="Upload Policy" open={policyUploadModal} footer={null} onCancel={() => setPolicyUploadModal(false)}>
              <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                  banned files.
                </p>
            </Dragger>
            </Modal>
            
          </MainPageScaffold>
        </Route>
      </Switch>
    </>
  );
};


const StyledAntTable = styled(AntTable)`
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
`;
