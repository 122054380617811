import React, { useState } from "react";
import { Table as AntTable, Tag, Popover, Modal } from "antd";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";
import { DocumentTable } from "./Documents";
import { TableTopBar } from "../../globalComponents/TableTopBar";
import { TableComponent } from "../../globalComponents/TableComponent";
import { PageTitleBar } from "../../globalComponents/PageTitleBar";
import { FiMoreVertical } from "react-icons/fi";
import { selectUser, suspendUser } from "./slice";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { sendVerificationEmail, activateUser, fetchAllUser } from "./slice";
import { useDispatch } from "react-redux";
import { StyledModal } from "../../globalComponents/styles";
import { notificationAlert } from "../../../utils/notificationAlert";
import { themes } from "../../../globalAssets/theme";
import { MainPageScaffold } from "../../globalComponents/MainPageScaffold";
import { Services } from "../../../services";

export const Documents  = (props) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const userState = useSelector(selectUser);
  const [activeTab, setActiveTab] = useState("1");
  const [verifyEmailProps, setVerifyEmailProps] = useState({
    loading: false,
    email: "",
    isModalVisible: false,
  });
  const [activateUserProps, setActivateUserProps] = useState({
    loading: false,
    email: "",
    isModalVisible: false,
  });
  const [suspendUserProps, setSuspendUserProps] = useState({
    loading: false,
    email: "",
    isModalVisible: false,
  });
  const [activeUserPageSize, setActiveUserPageSize] = useState(10);
  const [suspendedUserPageSize, setSuspendedUserPageSize] = useState(10);
  const [searchValues, setSearchValues] = useState([]);
  const [isSearching, setSearching] = useState(false);
  const dispatcher = useDispatch();

  
  const columns = [
    {
      title: "Document Name",
      dataIndex: "documentName",
      key: "documentName",
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (action, alldata) => {
        const content = (
          <div>
            <p
              style={{ cursor: "pointer" }}
            >
              View Document
            </p>
            <p
              style={{ cursor: "pointer" }}
            >
              Verify
            </p>
          </div>
        );

        return (
          <Popover trigger="click" content={content}>
            <FiMoreVertical />
          </Popover>
        );
      },
    },
  ];

  const handleUserSearch = (event) => {
    (async () => {
      try {
        console.log(event);
        setSearching(Boolean(event.target.value))
        const response = await Services.searchUser({
          search: event.target.value,
        });
        setSearchValues(response.data.data.body);
        console.log(response.data.data.body);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <MainPageScaffold onSearch={handleUserSearch} showSearch>
            <PageTitleBar hideButtons={true} title="User Document Verification" />
            <TableComponent 
            // onClick={}
            >
              {isSearching ? (
                <StyledAntTable
                  // onRow={handleRow("active")}
                  columns={columns}
                  dataSource={searchValues}
                  pagination={false}
                />
              ) : (
                    <StyledAntTable
                      // onRow={handleRow("active")}
                      columns={columns}
                      dataSource={userState.activeUserList.data}
                      pagination={{
                        total: userState.activeUserList.total,
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                        defaultCurrent: 1,
                        current: userState.activeUserList.page + 1,
                        pageSize: activeUserPageSize,
                        showSizeChanger: true,
                        // onChange: handleActiveUserPagination,
                      }}
                    />
              )}
            </TableComponent>
              <table id="allusers" style={{ display: "none" }}>
                <thead>
                  <tr>
                    {userState.activeUserList.data.length &&
                      Object.keys(userState.activeUserList.data[0]).map(
                        (item, index) => {
                          return <td key={index}>{item}</td>;
                        }
                      )}
                  </tr>
                </thead>
                <tbody>
                  {userState.activeUserList.data.map((item, index) => {
                    return (
                      <tr key={`${index}-item`}>
                        {Object.values(item)
                          .filter((item) => typeof item !== "object")
                          .map((data, index) => {
                            return <td key={`${index}-data`}>{data}</td>;
                          })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
          </MainPageScaffold>
        </Route>

        <Route path={`${path}/:email`}>
          <MainPageScaffold>
            {/* <DocumentTable
              // onSuspendUser={openSuspendUserModal}
              // onActivateUser={openActivateUserModal}
            /> */}
          </MainPageScaffold>
        </Route>
      </Switch>
      <SendEmailVerificatioModal
        email={verifyEmailProps.email}
        // onCancel={handleCloseSendVerificationModal}
        // onOk={handleSendVerificationEmail}
        loading={verifyEmailProps.loading}
        visible={verifyEmailProps.isModalVisible}
      />
      <ActivateUserModal
        email={activateUserProps.email}
        loading={activateUserProps.loading}
        visible={activateUserProps.isModalVisible}
        // onCancel={handleCloseActivateUserModal}
        // onOk={handleActivateUser}
      />
      <SuspendUserModal
        email={suspendUserProps.email}
        loading={suspendUserProps.loading}
        visible={suspendUserProps.isModalVisible}
        // onCancel={handleCloseSuspendUserModal}
        // onOk={handleSuspendUser}
      />
    </>
  );
};

export const SendEmailVerificatioModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Send"
      onCancel={props.onCancel}
    >
      <h3>Send Email Verification</h3>
      <p>
        Would you like to send email verification link to{" "}
        <strong>{props.email}</strong>
      </p>
    </StyledModal>
  );
};

export const ActivateUserModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
        },
      }}
      cancelButtonProps={{
        type: "danger",
      }}
      onOk={props.onOk}
      okText="Activate"
      onCancel={props.onCancel}
    >
      <h3>Activate User</h3>
      <p>
        Would you like to <strong>activate</strong> the user with this email{" "}
        <strong>{props.email}</strong>?
      </p>
    </StyledModal>
  );
};

export const SuspendUserModal = (props) => {
  return (
    <StyledModal
      visible={props.visible}
      okButtonProps={{
        loading: props.loading,
        type: "danger",
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: themes.primaryColor,
          border: `1px solid ${themes.primaryColor}`,
          color: "#fff",
        },
      }}
      onOk={props.onOk}
      okText="Suspend"
      onCancel={props.onCancel}
    >
      <h3>Suspend User</h3>
      <p>
        Would you like to{" "}
        <strong style={{ color: themes.red }}>suspend </strong>the user with
        this email <strong>{props.email}</strong>?
      </p>
    </StyledModal>
  );
};

const StyledAntTable = styled(AntTable)`
  & .ant-table-tbody > tr > td {
    cursor: pointer;
  }
`;
